// src/components/Callback.js
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const Callback = () => {
  const history = useHistory();

  useEffect(() => {
    // Check if the URL contains a token
    const hash = window.location.hash;
    if (hash) {
      const params = new URLSearchParams(hash.substring(1));
      const token = params.get('access_token');
      const expiresIn = params.get('expires_in');
      if (token && expiresIn) {
        const expireTime = new Date().getTime() + parseInt(expiresIn) * 1000;
        localStorage.setItem('kc-token', token);
        localStorage.setItem('kc-token-expires-in', expiresIn);
        localStorage.setItem('kc-token-expire-time', expireTime.toString());

        // Remove the token from the URL
        history.replace('/');
      }
    }
  }, [history]);

  return <div>Loading...</div>;
};

export default Callback;
