import React, { createContext, useState, useContext } from 'react';

// Define the shape of the modal context
type ModalContextType = {
    isOpen: boolean;
    modalTitle: string;
    modalContent: React.ReactNode | undefined;
    confirmText: string;
    onConfirm: (() => void) | null;
    openModal: (title: string, content: React.ReactNode, confirmText: string, confirmCallback: () => void, simpleModal?: boolean) => void; // Change here
    closeModal: () => void;
    simpleModal: boolean | undefined; // show action buttons or have custom interaction
};

// Create the context with an initial value of null
const ModalContext = createContext<ModalContextType | undefined>(undefined);

// Modal provider component
export const ModalProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("Default Title");
    const [modalContent, setModalContent] = useState<React.ReactNode | undefined>(undefined); // Change here
    const [confirmText, setConfirmText] = useState('Confirm');
    const [onConfirm, setOnConfirm] = useState<(() => void) | null>(null);
    const [simpleModal, setSimpleModal] = useState<boolean | undefined>(false);

    const openModal = (title: string, content: React.ReactNode, confirmText: string, confirmCallback: () => void, simpleModal: boolean | undefined) => { // Change here
        setModalTitle(title);
        setModalContent(content);
        setConfirmText(confirmText);
        setOnConfirm(() => confirmCallback);
        setIsOpen(true);
        setSimpleModal(simpleModal);
    };

    const closeModal = () => {
        setIsOpen(false);
        setModalContent(undefined);
        setConfirmText('Confirm');
        setOnConfirm(null);
    };

    return (
        <ModalContext.Provider value={{ isOpen, modalTitle, modalContent, confirmText, onConfirm, openModal, closeModal, simpleModal }}>
            {children}
        </ModalContext.Provider>
    );
};

// Custom hook to use the modal context
export const useModal = () => {
    const context = useContext(ModalContext);
    if (!context) {
        throw new Error("useModal must be used within a ModalProvider");
    }
    return context;
};
