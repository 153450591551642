const hostname = window.location.hostname;

type API_CONFIG_URLS = {
  API_BASE_URL: string;
  KEYCLOAK_URL: string;
}

type API_CONFIG = {
  production: API_CONFIG_URLS;
  local: API_CONFIG_URLS;
}

const config: API_CONFIG = {
  production: {
    API_BASE_URL: 'https://release-dashboard-backend.fulfil.store',
    KEYCLOAK_URL: 'https://auth-prod.fulfil-api.com/auth/realms/fulfil/protocol/openid-connect/auth',
  },
  local: {
    API_BASE_URL: 'http://localhost:4000',
    KEYCLOAK_URL: 'https://auth-staging.np-fulfil-api.com/auth/realms/fulfil/protocol/openid-connect/auth',
  }
};

const selectedConfig: API_CONFIG_URLS = hostname === 'localhost' ? config.local : config.production;

export default selectedConfig;
