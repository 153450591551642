import React from 'react';
import { useModal } from './ModalContext';
import ConfirmModal from '../../modals/ConfirmModal';

const ModalTrigger = () => {
    const { openModal } = useModal();

    const handleOpenModal = () => {
        openModal("Confirm Action", <ConfirmModal />, 'Confirm', handleConfirm, true);
    };

    const handleConfirm = () => {
        alert("Confirmed! Action executed.");
        // Add your action logic here
    };

    return (
        <button onClick={handleOpenModal}>Open Modal</button>
    );
};

export default ModalTrigger;