import React, { useState } from 'react';
import xhr from '../../../lib/xhr';
import DeployModal from './modals/DeployModal';
import config from '../../../lib/config';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import ChecklistIcon from '@mui/icons-material/Checklist';
import ApartmentIcon from '@mui/icons-material/Apartment';
import AirIcon from '@mui/icons-material/Air';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import CertifiedIcon from '@mui/icons-material/NewReleases';
import Tooltip from '@mui/material/Tooltip';
import { Button } from '@mui/material';
import { useModal } from './core/Modal/ModalContext';

const steps = [
  { name: 'release cut', icon: ContentCutIcon },
  { name: 'testbook complete', icon: ChecklistIcon },
  { name: 'tested on sim', icon: ApartmentIcon },
  { name: 'tested on whis', icon: AirIcon },
  { name: 'pio stability IT', icon: PrecisionManufacturingIcon },
  { name: 'certified', icon: CertifiedIcon },
  { name: 'released', icon: RocketLaunchIcon }
];

const CommitRow: React.FC<any> = ({ commit, refreshCommits, isContinuousDeployment, deployedMap, service }) => {
  const { openModal } = useModal();
  const [stepStatuses, setStepStatuses] = useState<any>(() => {
    const statuses: any = {};
    steps.forEach(step => {
      statuses[step.name] = commit.steps?.[step.name] ? { completed: true, timestamp: commit.steps[step.name] } : { completed: false };
    });
    return statuses;
  });

  const openDeployModal = () => {
    openModal(
        `Deploy ${service.service_name} to Version ${commit.tags[0]}`,
        <DeployModal service={service} commit={commit} />,
    'Deploy',
        () => {},
        false)
  };

  const toggleStep = (step_name: string) => {
    xhr.post(`${config.API_BASE_URL}/api/release-steps/toggle-step`, {
      image_digest: commit.digest,
      step_name
    })
      .then(response => {
        setStepStatuses({
          ...stepStatuses,
          [step_name]: { completed: response.data.completed, timestamp: response.data.timestamp }
        });
      })
      .catch(error => console.error('Error toggling step:', error));
  };

  const { digest, tags, timestamp, releases } = commit;

  const formatTimestamp = (timestamp: string) => {
    const date = new Date(timestamp);
    return date.toLocaleString().replace(/,/g, '').replace(/:([0-5]\d)\s/, '-$1 ').toLowerCase();
  };

  const formatDigest = (digest: string) => digest.replace('sha256:', '').substring(0, 12);

  return (
    <>
      <tr>
        <td>
          <img src='/docker-ico.png' alt="docker icon" width="18" height="18" style={{ verticalAlign: 'middle', marginRight: '5px' }} />
          {formatDigest(digest)}
        </td>
        <td>
          {(tags || []).map((tag: string) => (
            <span key={tag} className={`tag-pill ${tag === 'latest' ? 'latest-pill' : ''}`}>
              {tag}
            </span>
          ))}
          {tags && tags.map((tag: string) => (
            deployedMap[tag] && deployedMap[tag].map((location: string) => (
              <span key={`${tag}-${location}`} className="tag-pill deployed-pill">
                {location}
              </span>
            ))
          ))}
        </td>
        <td>{timestamp ? formatTimestamp(timestamp.datetime || timestamp) : 'Invalid date'}</td>
        {!isContinuousDeployment && (
          <>
            <td>
              {(releases || []).map((r: {environment: string, timestamp: string}) => (
                  <Tooltip key={digest} title={`Deployed on ${formatTimestamp(r.timestamp)}`}>
                    <span key={r.environment} className="tag-pill">
                      {r.environment}
                    </span>
                  </Tooltip>
              ))}
            </td>
            <td>
              <Tooltip key={digest} title='commits newTag property in kustomization file with selected version tag'>
                {/* need the span so the tooltip can hav an anchor to present the data, without the disabled button doesn't bubble up the tooltip text box */}
                <span>
                  <Button variant="contained" onClick={() => openDeployModal()}>Deploy</Button>
                </span>
              </Tooltip>
            </td>
          </>
        )}
        <td>
          {steps.map(step => {
            const StepIcon = step.icon;
            return (
              <Tooltip key={step.name} title={stepStatuses[step.name]?.completed ? `${step.name} completed ${formatTimestamp(stepStatuses[step.name].timestamp)}` : step.name}>
                <StepIcon
                  onClick={() => toggleStep(step.name)}
                  style={{
                    cursor: 'pointer',
                    marginRight: '5px',
                    color: stepStatuses[step.name]?.completed ? '#2CB673' : 'grey'
                  }}
                />
              </Tooltip>
            );
          })}
        </td>
      </tr>
    </>
  );
};

export default CommitRow;