import config from './config';

export const getKeycloakUrl = () => {
  const keycloakBaseUrl = config.KEYCLOAK_URL;
  const redirectUri = `${window.location.origin}/callback`;
  const clientId = 'release_dashboard';

  return `${keycloakBaseUrl}?client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&response_type=token`;
};

export const refreshToken = async () => {
  const refreshToken = localStorage.getItem('kc-refresh-token');
  if (!refreshToken) {
    throw new Error('No refresh token available');
  }

  const keycloakBaseUrl = config.KEYCLOAK_URL;
  const clientId = 'release_dashboard';

  const response = await fetch(`${keycloakBaseUrl}/token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: new URLSearchParams({
      grant_type: 'refresh_token',
      client_id: clientId,
      refresh_token: refreshToken
    }),
  });

  if (!response.ok) {
    throw new Error('Token refresh failed');
  }

  const data = await response.json();
  const { access_token, expires_in, refresh_token } = data;
  const expireTime = new Date().getTime() + expires_in * 1000;

  localStorage.setItem('kc-token', access_token);
  localStorage.setItem('kc-token-expires-in', expires_in);
  localStorage.setItem('kc-token-expire-time', JSON.stringify(expireTime));
  localStorage.setItem('kc-refresh-token', refresh_token);

  return access_token;
};