import React, { useState } from 'react';
import {
    Alert,
    Box, Button,
    Checkbox,
    Chip,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent, Snackbar,
    styled
} from '@mui/material';
import { WarningAmber } from '@mui/icons-material';
import { useModal } from '../core/Modal/ModalContext';
import xhr from '../../../../lib/xhr';
import config from '../../../../lib/config';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const ModalContainer = styled(`div`)({
    display: 'flex',
    flexDirection: 'column',
    gridGap: '1rem',
})

const RollbackWarning = styled(`div`)({
    border: '2px solid goldenrod',
    background: 'lightyellow',
    borderRadius: '0.25rem',
    color: 'black',
    fontSize: '1.25rem',
    fontWeight: 600,
    display: 'flex',
    gridGap: '0.5rem',
    alignItems: 'center',
    padding: '0.5rem'
})

const ModalButtonGroup = styled(`div`)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    gridGap: '1rem',
});

const DeployModal: React.FC<any> = ({service, commit}) => {
    const {closeModal, confirmText} = useModal();
    const [environments, setEnvironments] = React.useState<string[]>([]);
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [creatingCommit, setCreatingCommit] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>('');
    const handleChange = (event: SelectChangeEvent<typeof environments>) => {
        const {
            target: { value },
        } = event;
        setEnvironments(
            typeof value === 'string' ? value.split(',') : value,
        );
    }

    const handleSubmit = async () => {
        setCreatingCommit(true);
        setSnackbarMessage(`Submitting Deploy Commit.`);
        setSnackbarOpen(true);
        try {
            await submitRelease()
            setSnackbarOpen(false);
        } catch (e: any) {
            setSnackbarMessage(e);
        } finally {
            setSnackbarOpen(true);
            setSnackbarMessage(`Submitted Deployment Commit.`);
            setCreatingCommit(false);
        }
    };

    const handleSnackbarClose = (event: any, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const submitRelease = () => {
        const payload = {
            service_id: commit.service_id,
            build: commit.digest,
            tag: commit.tags[0],
            environments
        };
        return xhr.post(`${config.API_BASE_URL}/api/releases`, payload)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                console.error('Error creating release:', error);
                return Promise.reject(error.response?.data?.error || 'An error occurred while creating the release');
            });
    }


    return (
        <ModalContainer>
            <RollbackWarning>
                <WarningAmber />
                <p>Before proceeding, please be aware that this action is irreversible and will immediately revert your deployment to a previous state.</p>
            </RollbackWarning>
            <FormControl sx={{ mb: 1, width: 300 }}>
                <InputLabel>Environments</InputLabel>
                <Select
                    value={environments}
                    onChange={handleChange}
                    input={<OutlinedInput label="Environments" />}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                                <Chip key={value} label={value} />
                            ))}
                        </Box>
                    )}
                    MenuProps={MenuProps}
                >
                    {service.facilities.map((facility: string) => (
                        <MenuItem
                            key={facility}
                            value={facility}
                        >
                            <ListItemText primary={facility} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <ModalButtonGroup>
                <Button variant="contained" onClick={closeModal}>Close</Button>
                    <Button
                        disabled={creatingCommit}
                        variant="contained"
                        onClick={async () => {
                            await handleSubmit();
                        }}>
                        {confirmText}
                    </Button>
            </ModalButtonGroup>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <Alert onClose={handleSnackbarClose} severity="warning" sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </ModalContainer>
    )
}

export default DeployModal;