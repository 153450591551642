import React, { useState, useEffect } from 'react';
import ServiceList from '../../features/app/components/ServiceList';
import ServiceDetails from './ServiceDetails';
import ManageServices from './ManageServices';
import Callback from '../../features/app/components/Callback';
import LandingComponent from './Landing';
import './App.css';
import { BrowserRouter as Router, Route, Switch, useParams, Link } from 'react-router-dom';
import { getKeycloakUrl } from '../../lib/auth';
import {ModalProvider} from "../../features/app/components/core/Modal/ModalContext";
import Modal from "../../features/app/components/core/Modal/Modal";
import ModalTrigger from "../../features/app/components/core/Modal/ModalTrigger";

console.log(`Using NODE_ENV: ${process.env.NODE_ENV}, REACT_APP_ENV: ${process.env.REACT_APP_ENV}`);

const App = () => {
  const [, setSelectedService] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('kc-token');
    const tokenExpiresIn = localStorage.getItem('kc-token-expires-in');
    const tokenExpireTime = localStorage.getItem('kc-token-expire-time');

    if (!token || !tokenExpiresIn || !tokenExpireTime || new Date().getTime() > Number(tokenExpireTime)) {
      // Token is either not present or expired, redirect to Keycloak login
      window.location.href = getKeycloakUrl();
    } else {
      // Set up a timer to refresh the token before it expires
      const timeUntilExpiration = Number(tokenExpireTime) - new Date().getTime() - 60000; // Refresh 1 minute before expiration
      setTimeout(() => {
        window.location.href = getKeycloakUrl();
      }, timeUntilExpiration);
    }
  }, []);

  const ServiceDetailsRoute = () => {

    const { serviceId } = useParams() as {serviceId: string};
    return <ServiceDetails serviceId={serviceId} />;
  };

  return (
    <Router>
      <ModalProvider>
        <div className="App">
          <div className="sidebar">
            <div className="logo">
              <Link to="/">
                <img src="https://dashboard.fulfil.store/assets/images/logo_white.png" alt="logo" />
              </Link>
            </div>
            <ServiceList setSelectedService={setSelectedService} />
          </div>
          <div className="content">
            <Switch>
              <Route exact path="/" component={LandingComponent} />
              <Route path="/service/:serviceId" component={ServiceDetailsRoute} />
              <Route path="/manage-services" component={ManageServices} />
              <Route path="/callback" component={Callback} />
            </Switch>
          </div>
        </div>
        <ModalTrigger />
        <Modal />
      </ModalProvider>
    </Router>
  );
};

export default App;